import React from "react";
import LayoutWrapper from "../component/LayoutWrapper/Index";
import SellHome from "../component/Sell/Sell";

const Sell = () => {
  return (
    <LayoutWrapper
      title="Sell Your San Diego Home | Real Estate | The Selby Team"
      desc="Find out how much your home is worth from experienced realtors, rather than an algorithm giving you inaccurate information. Call The Selby Team."
      headerLogo="../../images/Selby_logo_v2.svg"
      headerId="header_v2"
    >
      <SellHome />
    </LayoutWrapper>
  );
};

export default Sell;
